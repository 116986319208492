import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.jpg'
import IMG6 from '../../assets/portfolio6.jpg'



const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
       
              <article className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={IMG5} alt="" />
                </div>
                    <h3>Foodgram</h3>
                <div className="portfolio__item-cta">
                    <a href="https://github.com/jkyan1988/foodgram" className="btn">Github</a>
                    <a href="https://www.linkedin.com/posts/activity-6970049817171353601-MtcH?utm_source=share&utm_medium=member_desktop" className="btn btn-primary" target='_blank'>Live Demo</a>
                </div>
              </article>
              <article className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={IMG4} alt="" />
                </div>
                    <h3>Nelp</h3>
                <div className="portfolio__item-cta">
                    <a href="https://github.com/jkyan1988/phase-4-project" className="btn">Github</a>
                    <a href="https://www.linkedin.com/posts/activity-6975507723404615681-cawO?utm_source=share&utm_medium=member_desktop" className="btn btn-primary" target='_blank'>Live Demo</a>
                </div>
              </article>
              <article className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={IMG3} alt="" />
                </div>
                    <h3>Betterloxd</h3>
                <div className="portfolio__item-cta">
                    <a href="https://github.com/justinmramon/phase-3-project-frontend" className="btn">Github</a>
                    <a href="https://github.com/justinmramon/phase-3-project-backend" className="btn">Github</a>
                    <a href="https://www.linkedin.com/posts/activity-6975497046820581377-xWaf?utm_source=share&utm_medium=member_desktop" className="btn btn-primary" target='_blank'>Live Demo</a>
                </div>
              </article>
              <article className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={IMG2} alt="" />
                </div>
                    <h3>To Do List App</h3>
                <div className="portfolio__item-cta">
                    <a href="https://github.com/lorihenderson/productivity-app-attempt2" className="btn">Github</a>
                    <a href="https://www.linkedin.com/posts/activity-6959863758609358848-nCSy?utm_source=share&utm_medium=member_desktop" className="btn btn-primary" target='_blank'>Live Demo</a>
                </div>
              </article>
              <article className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={IMG1} alt="" />
                </div>
                    <h3>Meme Generator</h3>
                <div className="portfolio__item-cta">
                    <a href="https://github.com/jkyan1988/Development-code-school-PHASE1-Project" className="btn">Github</a>
                    <a href="https://www.linkedin.com/posts/activity-6959865275361980416-3afO?utm_source=share&utm_medium=member_desktop" className="btn btn-primary" target='_blank'>Live Demo</a>
                </div>
              </article>
              <article className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={IMG6} alt="" />
                </div>
                    <h3>"Candy Crush"</h3>
                <div className="portfolio__item-cta">
                    <a href="https://github.com/jkyan1988/candy-crush" className="btn">Github</a>
                    <a href="https://www.loom.com/share/6db29781f7fa410aae70b729019e4a82" className="btn btn-primary" target='_blank'>Live Demo</a>
                </div>
              </article>
              
      </div>
    </section>
  )
}

export default Portfolio