import React from 'react'
import './contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { AiOutlineLinkedin } from 'react-icons/ai'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_o26sfdj', 'template_s83646z', form.current, 'zq9_ZyzYMlu-fvV6n')
    e.target.reset();
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>james.kyan88@gmail.com</h5>
            <a href="mailto:james.kyan88@gmail.com">Send a message</a>
          </article >
          <article className="contact__option">
            <AiOutlineLinkedin className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            <h5>James Kyan</h5>
            <a href="https://www.linkedin.com/in/james-kyan/">Send a message</a>
          </article >
        </div>
          {/* END OF CONTACT OPTIONS */}
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder="Your Full Name" required />
            <input type="email" name='email' placeholder="Your Email" required />
            <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
            <button type="submit" className='btn btn-primary'>Submit</button>
          </form>
      </div>
    </section>
  )
}

export default Contact