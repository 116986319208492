import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from teammates</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
       // install Swiper modules
       modules={[Pagination]}
       spaceBetween={40}
       slidesPerView={1}
       pagination={{ clickable: true }}>
          
                <SwiperSlide className="testimonial">
                <div className="client__avatar">
                  <img src={AVTR1}/>
                </div>
                  <h5 className="client__name">Alexandra</h5>
                  <small className="client__review">
                  James and I attend Flatiron School’s Software Engineering bootcamp together, 
                  and I was given the opportunity to work alongside him into a collaborative project. 
                  Throughout the program, James was one of the most dependable and helpful people I knew. 
                  Working on our Phase 2 project together was extremely rewarding, and I would work with him again in a heartbeat. 
                  James’ “let’s hit the ground running” attitude propelled our group forward, even when the rest of us felt 
                  like our final product would never come to fruition. His dedication, intelligence, and willingness to help 
                  wherever he was needed proved how much of an asset he is to any company. 
                  </small>
                </SwiperSlide>

                <SwiperSlide className="testimonial">
                <div className="client__avatar">
                  <img src={AVTR2}/>
                </div>
                  <h5 className="client__name">Joon</h5>
                  <small className="client__review">
                  James and I attended Flatiron school and were in the same software engineering program in 2022. 
                  When I say there isn’t a more qualified, kindhearted, considerate, hardworking developer I know. I am telling you the absolute truth. 
                  For what I have witnessed James always strives to reach out a helping hand to just about anyone and finds creative strategies for problem solving on a regular basis.  
                  </small>
                </SwiperSlide>

                <SwiperSlide className="testimonial">
                <div className="client__avatar">
                  <img src={AVTR3}/>
                </div>
                  <h5 className="client__name">Victor</h5>
                  <small className="client__review">
                  I had the pleasure of working alongside James while going through Flatiron School’s rigorous SE program. 
                  He was consistently a knowledgeable and willing collaborator, always willing to talk through problems and think of optimal solutions. 
                  I learned from James how to articulate issues I had with my code in a way that was productive and enlightening. 
                  James also showed admirable tenacity; he took on any challenge presented to him with cheerful optimism. 
                  Time and again I was impressed by his professionality and dependability. I’d happily recommend him to anyone looking for a skilled, reliable developer.
                  </small>
                </SwiperSlide>

                <SwiperSlide className="testimonial">
                <div className="client__avatar">
                  <img src={AVTR4}/>
                </div>
                  <h5 className="client__name">Justin</h5>
                  <small className="client__review">
                  I had the absolute joy of attending Flatiron School with James over the last several months. 
                  I've found his ability to juggle multiple tasks and go beyond expectations to be truly astounding. 
                  He is one of the few people who has managed to maintain a job while working through the course material with the rest of us, 
                  a feat that should not be taken lightly. Not only has James managed both responsibilities. 
                  He has managed to exceed expectations in regards to programming concepts, regularly displaying a willingness to help other classmates in their growth as well. James would be an asset to any company and will rise to the occasion under any circumstances. I can't recommend him enough.
                  </small>
                </SwiperSlide>
      </Swiper>
    </section>
  )
}

export default Testimonials