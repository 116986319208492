import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { BiHomeAlt } from 'react-icons/bi'

const About = () => {
  return (
    <section id="about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Flatiron School Graduate</h5>
              <small>Software Engineering</small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Hospitality Management</h5>
              <small>Decade of Experience</small>
            </article>
            <article className="about__card">
              <BiHomeAlt className="about__icon" />
              <h5>New Yorker</h5>
              <small>Born and raised</small>
            </article>

          
          </div>
          <p>
          I am a Software Engineer, former hospitality manager, a problem solver, a critical thinker, a team player, and a programmer at heart. 
          I love to learn about new technology and build things from scratch which is why I am in the world of software engineering, 
          where I can always keep learning, growing, and improving myself.
          
          </p>
          <a href="#contact" className="btn btn-primary">Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About