import React from 'react'
import { BsLinkedin, BsMedium } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'


const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://www.linkedin.com/in/james-kyan/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/jkyan1988" target="_blank"><FaGithub /></a>
        <a href="https://medium.com/@james.kyan88" target="_blank"><BsMedium /></a>
    </div>
  )
}

export default HeaderSocials